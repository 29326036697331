<template>
    <div class="form-access">
        <el-form
            @submit.native.prevent
            ref="loginForm"
            :model="loginForm"
            :rules="loginFormRules">
            <div class="mb-3">
                <img class="img-fluid" src="./../../assets/img/infibo-logo.svg" alt="inFibo Logo">
            </div>
            <p class="text-muted text-center mb-3">{{ $t('signInToContinue') }}</p>
            <el-form-item prop="email">
                <el-input
                    clearable
                    v-model="loginForm.email"
                    :disabled="sessionsLoadingState"
                    :placeholder="$t('enterEmail')"
                    @keyup.enter.native="submitLoginForm"/>
            </el-form-item>
            <el-form-item prop="password">
                <el-input
                    clearable
                    show-password
                    v-model="loginForm.password"
                    :disabled="sessionsLoadingState"
                    :placeholder="$t('enterPassword')"
                    @keyup.enter.native="submitLoginForm"/>
            </el-form-item>
            <el-form-item>
                <el-button
                    class="w-100"
                    type="primary"
                    @click="submitLoginForm"
                    :disabled="sessionsLoadingState"
                    :loading="sessionsLoadingState">{{ $t('signIn') }}
                </el-button>
            </el-form-item>
        </el-form>
        <div class="form-group text-center">
            <router-link class="text-muted text-decoration-none" to="/auth/password-reset">
                {{ $t('forgotYourPassword') }}
            </router-link>
        </div>
    </div>
</template>

<script>
import {changeLocale} from '@/plugins/i18n'

export default {
    name: 'Login',
    i18n: {
        messages: {
            en: {
                signInToContinue: 'Sign in to continue',
                enterEmail: 'Enter email',
                password: 'Password',
                enterPassword: 'Enter password',
                signIn: 'Sign In',
                forgotYourPassword: 'Forgot your password?',
                wrongEmail: 'Invalid Email entered',

                responseErrors: {
                    unknownErrorTitle: 'Unknown error',
                    unknownErrorMessage: 'Contact support',

                    networkErrorTitle: 'Network error',
                    networkErrorMessage: 'Check your connection or try again later',

                    wrongEmailOrPasswordTitle: 'Incorrect email or password entered',
                    wrongEmailOrPasswordMessage: 'Check the entered data and try again',

                    serverErrorTitle: 'Server error',
                    serverErrorMessage: 'Contact support',
                }
            },
            ru: {
                signInToContinue: 'Войдите, чтобы продолжить',
                enterEmail: 'Введите email',
                password: 'Пароль',
                enterPassword: 'Введите пароль',
                signIn: 'Войти',
                forgotYourPassword: 'Забыли пароль?',
                wrongEmail: 'Введен неверный Email',

                responseErrors: {
                    unknownErrorTitle: 'Неизвестная ошибка',
                    unknownErrorMessage: 'Свяжитесь со службой поддержки',

                    networkErrorTitle: 'Ошибка сети',
                    networkErrorMessage: 'Проверьте подключение или повторите попытку позже',

                    wrongEmailOrPasswordTitle: 'Введен неверный email или пароль',
                    wrongEmailOrPasswordMessage: 'Проверте введенные данные и повторите попытку',

                    serverErrorTitle: 'Ошибка сервера',
                    serverErrorMessage: 'Свяжитесь со службой поддержки',
                }
            }
        }
    },
    data: function () {
        return {
            loginForm: {
                email: '',
                password: '',
            },
            loginFormRules: {
                //https://github.com/yiminghe/async-validator
                email: [
                    {
                        required: true,
                        message: () => this.$t('enterEmail'),
                    },
                    {
                        type: 'email',
                        message: () => this.$t('wrongEmail'),
                    }
                ],
                password: [
                    {
                        required: true,
                        message: () => this.$t('enterPassword'),
                    },
                ],
            },
        }
    },
    computed: {
        sessionsLoadingState: function () {
            return this.$store.getters.SESSION_LOADING_STATE
        }
    },
    methods: {
        changeLocale,
        submitLoginForm: function () {
            // Validate
            this.$refs['loginForm'].validate(valid => {
                if (!valid) {
                    return false
                }

                // Try to create new token
                this.$store.dispatch('SESSION_CREATE_REQUEST', this.loginForm)
                    .then(() => {
                        return this.$store.dispatch('PROFILE_GET_REQUEST')
                    })
                    .then(() => {
                        this.$router.push('/').catch(() => {})
                    })
                    .catch((e) => {
                        let errorTitle = this.$t('responseErrors.unknownErrorTitle')
                        let errorMessage = this.$t('responseErrors.unknownErrorMessage')

                        if (!e.response) {
                            errorTitle = this.$t('responseErrors.networkErrorTitle')
                            errorMessage = this.$t('responseErrors.networkErrorMessage')
                        }

                        if (e.response && (e.response.status === 400 || e.response.status === 422)) {
                            errorTitle = this.$t('responseErrors.wrongEmailOrPasswordTitle')
                            errorMessage = this.$t('responseErrors.wrongEmailOrPasswordMessage')
                        }

                        if (e.response && e.response.status >= 500) {
                            errorTitle = this.$t('responseErrors.serverErrorTitle')
                            errorMessage = this.$t('responseErrors.serverErrorMessage')
                        }

                        this.$notify({
                            title: errorTitle,
                            message: errorMessage,
                            type: 'error',
                            duration: 4000,
                            showClose: false,
                            dangerouslyUseHTMLString: true,
                        })
                    })
            })
        }
    }
}
</script>

<style scoped>
.form-access {
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 14px -6px;
    border-radius: 8px;
    padding: 30px;
    border: 1px solid #f2f4f9;
    max-width: 350px;
}
</style>
